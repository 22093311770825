import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "P:/falqon/ortoapp/ortoapp/frontend/support/app_support/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "a-nova-era-das-consultas"
    }}>{`A nova era das consultas!`}</h1>
    <p>{`Agendamento de consultas, biblioteca colaborativa, plataforma de comunicação ágil, algoritmo inteligente de agendamento de consultas, tudo em um único lugar para otimizar a relação médico-paciente.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      